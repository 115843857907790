import { post } from '@/http/http'
// 公司添加
export const companyAdd = p => post('api/v1.0/companyinfo/companyinfo_add', p)

// 公司编辑
export const companyUpdate = p => post('api/v1.0/companyinfo/companyinfo_update', p)

// 公司删除
export const companyDelete = p => post('api/v1.0/companyinfo/companyinfo_delete', p)

// 公司查询
export const companyGet = p => post('api/v1.0/companyinfo/companyinfo_get', p)
