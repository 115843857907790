<template>
<!-- 公司管理 -->
  <div class="whiteBg">
    <div>
      <div class="el-icon-plus settingAdd" @click="handleAdd"><span> 新增</span></div>
      <div class="table_cont">
        <el-table
          :header-cell-style="{background:'#EBF2FE'}"
          :data="comanyList"
          style="width: 100%">
          <el-table-column
            label="序号"
            type="index">
          </el-table-column>
          <el-table-column
            label="单位名称"
            prop="companyname">
          </el-table-column>
          <el-table-column
            label="单位电话"
            prop="companyphone">
          </el-table-column>
          <el-table-column
            label="单位网址"
            prop="companyurl">
          </el-table-column>
          <el-table-column
            label="单位地址"
            prop="companyaddr">
          </el-table-column>
          <el-table-column
            label="单位logo"
            prop="furl">
            <template slot-scope="scope">
              <img :src="scope.row.furl" width="40" height="40" :onerror="notImg" class="head_pic"/>
            </template>
          </el-table-column>
          <el-table-column
            label="操作">
            <template slot-scope="scope">
              <el-button type="text" size="small"  style="color:#3C84EF;" @click="handleEdit(scope.row)">编辑</el-button>
              <el-button type="text" size="small"  style="color:#F44C8E;" @click="handleDelete(scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <el-pagination
        @current-change="PageChange"
        :current-page="page"
        :page-sizes="[10]"
        :page-size="size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        class="settingPage">
      </el-pagination>
    </div>

    <!-- 新增弹窗 -->
    <el-dialog title="新增" :visible.sync="addFromShow" width="600px" center class="gyDialog" :close-on-click-modal="false" :before-close="addFromCancel">
      <el-form :model="addfrom" :rules="formRules" ref="addFrom">
        <el-form-item label="单位名称" class="foItem"  prop="companyname">
          <el-input v-model="addfrom.companyname"  placeholder="请输入单位名称"></el-input>
        </el-form-item>
        <div class="flexCB">
          <div class="flexCB foItem">
            <el-form-item label="单位电话"  prop="companyphone">
              <el-input v-model="addfrom.companyphone"  placeholder="请输入单位电话" class="foFSet"></el-input>
            </el-form-item>
            <el-form-item label="单位网址"  prop="companyurl">
              <el-input v-model="addfrom.companyurl"  placeholder="请输入单位网址" class="foFSet"></el-input>
            </el-form-item>
          </div>
        </div>
        <el-form-item label="单位地址" prop="companyaddr" class="foItem">
          <el-input v-model="addfrom.companyaddr" placeholder="请输入单位地址"></el-input>
        </el-form-item>
        <div class="foItem">
          <label class="from_label">单位logo</label>
          <Upload :fileType="'jpg/png/jpeg'" :refresh="refresh" @uploadSuccess="addImgSuccess"></Upload>
        </div>
      </el-form>
      <div class="flexCE">
        <div class="settingCancel" @click="addFromCancel">取消</div>
        <div class="settingConfirm" @click="AddFromConfirm">确定</div>
      </div>
    </el-dialog>

    <!-- 编辑弹窗 -->
    <el-dialog title="新增" :visible.sync="editFromShow" width="600px" center class="gyDialog" :close-on-click-modal="false" :before-close="editFromCancel">
      <el-form :model="editfrom" :rules="editformRules" ref="editFrom">
        <el-form-item label="单位名称" class="foItem"  prop="companyname">
          <el-input v-model="editfrom.companyname"  placeholder="请输入单位名称"></el-input>
        </el-form-item>
        <div class="flexCB">
          <div class="flexCB foItem">
            <el-form-item label="单位电话" prop="companyphone">
              <el-input v-model="editfrom.companyphone"  placeholder="请输入单位电话"></el-input>
            </el-form-item>
            <el-form-item label="单位网址"  prop="companyurl">
              <el-input v-model="editfrom.companyurl"  placeholder="请输入单位网址"></el-input>
            </el-form-item>
          </div>
        </div>
        <div class="flexCB">
          <div class="flexCB foItem">
            <el-form-item label="公司管理员账号" >
              <el-input v-model="'admin' + editfrom.companycode" disabled placeholder="公司管理员账号"></el-input>
            </el-form-item>
            <el-form-item label="公司管理员密码">
              <el-input placeholder="888888" disabled></el-input>
            </el-form-item>
          </div>
        </div>
        <el-form-item label="单位地址" prop="companyaddr" class="foItem">
          <el-input v-model="editfrom.companyaddr" placeholder="请输入单位地址"></el-input>
        </el-form-item>
        <div class="foItem">
          <label class="from_label">单位logo</label>
          <Upload :fileType="'jpg/png/jpeg'" :refresh="refresh" :picture="editfrom.furl" @uploadSuccess="editImgSuccess"></Upload>
        </div>
      </el-form>
      <div class="flexCE">
        <div class="settingCancel" @click="editFromCancel">取消</div>
        <div class="settingConfirm" @click="editFromConfirm">确定</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Upload from '@/components/upload.vue'
import { companyGet, companyAdd, companyUpdate, companyDelete} from '@/api/company.js'
export default {
  components: {
    Upload
  },
  data () {
    // 单位电话校验
    const userphone = (rule, value, callback) => {
      if(value){
        if ( /^1/.test( value ) ) {
          if ( /^1\d{10}$/.test( value ) ) {
            callback();
          } else {
            callback( new Error( "请输入11位的手机号" ) );
          }
        } else if ( /^0/.test( value ) ) {
          if ( /^0\d{2,4}-?\d{6,9}$/.test( value ) ) {
            callback();
          } else {
            callback( new Error( "请输入正确的座机号" ) );
          }
        }else{
          callback( new Error( "请输入11位手机号或座机号" ) );
        }
      }else{
          callback();
      }
    }
    return {
      comanyList: [],
      page: 1,
      size: 10,
      total: 0,
      addFromShow: false,
      addfrom: {
        companyname: '',
        companyaddr: '',
        companyphone: '',
        companylogo: '',
        companyurl: ''
      },
      formRules: {
        companyname: [
          { required: true, trigger: 'blur', message: '单位名称不能为空' },
          { min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' }
        ],
        companyphone: [
          { required: true, trigger: 'blur', message: '单位电话不能为空' },
          { required: true, validator: userphone, trigger: 'change' }
        ],
        companyaddr: [
          { required: true, trigger: 'change', message: '请选择单位地址' }
        ],
      },
      refresh: false,
      editFromShow: false,
      editfrom: {},
      editformRules: {
        companyname: [
          { required: true, trigger: 'blur', message: '单位名称不能为空' },
          { min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' }
        ],
        companyphone: [
          { required: true, trigger: 'blur', message: '单位电话不能为空' },
          { required: true, validator: userphone, trigger: 'change' }
        ],
        companyaddr: [
          { required: true, trigger: 'change', message: '请选择单位地址' }
        ],
      },
      notImg: require('@/assets/img/users.png')
    }
  },
  created () {
    // this.companyid = JSON.parse(window.localStorage.getItem('loginInfo')).companyid
    this.init()
  },
  methods: {
    init () {
      const params = {
        // companyid: this.companyid,
        // companyname: this.companyname,
        page_index: this.page,
        page_size: this.size
      }
      companyGet(params).then(res => {
        if (res.status === 1) {
          this.comanyList = res.data.data
          this.total = res.data.recordcount
        } else {
          this.comanyList = []
          this.total = 0
        }
      })
    },
    PageChange (page) {
      this.page = page
      this.init()
    },
    // 新增
    handleAdd () {
      this.addFromShow = true
      this.refresh = !this.refresh
      this.comanyList = []
    },
    addImgSuccess (res) {
      this.addfrom.companylogo = res.fid
    },
    AddFromConfirm () {
      this.$refs.addFrom.validate((valid) => {
        if (!valid) return
        const params = {
          companyname: this.addfrom.companyname,
          companyphone: this.addfrom.companyphone,
          companyaddr: this.addfrom.companyaddr,
          companylogo: this.addfrom.companylogo,
          companyurl: this.addfrom.companyurl
        }
        companyAdd(params).then(res => {
          if (res.status === 1) {
            this.$message.success(res.message)
            this.addFromCancel()
            this.init()
          } else {
            this.$message.error(res.message)
          }
        })
      })
    },
    addFromCancel () {
      this.$refs.addFrom.resetFields()
      this.addFromShow = false
    },
     // 编辑
    handleEdit (row) {
      this.editFromShow = true
      this.editfrom = {...row}
    },
    editImgSuccess (res) {
      this.editfrom.companylogo = res.fid
    },
    editFromConfirm () {
      this.$refs.editFrom.validate((valid) => {
        if (!valid) return
        const params = {
          companyid: this.editfrom.companyid,
          companyname: this.editfrom.companyname,
          companyphone: this.editfrom.companyphone,
          companyaddr: this.editfrom.companyaddr,
          companylogo: this.editfrom.companylogo,
          companyurl: this.editfrom.companyurl
        }
        companyUpdate(params).then(res => {
          if (res.status === 1) {
            this.$message.success(res.message)
            this.editFromCancel()
            this.init()
          } else {
            this.$message.error(res.message)
          }
        })
      })
    },
    editFromCancel () {
      this.editFromShow = false
      this.$refs.editFrom.resetFields()
    },
    // 删除
    handleDelete (row) {
      this.$confirm('此操作将永久删除该信息, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true
      }).then(() => {
        companyDelete({ companyid: row.companyid }).then(res => {
          if (res.status === 1) {
            this.$message.success(res.message)
            this.init()
          } else {
            this.$message.error(res.message)
          }
        })
      }).catch(() => {})
    },
  }
}
</script>

<style scoped>
.foItem{
  width: 500px;
}
.el-form {
  padding-left: 20px;
}
.el-form-item >>> .el-form-item__label, .from_label {
  color: #576271;
}
</style>
